$primary: #006cb5;
$orange: #f85d49;
$orange-hover: #f85d49;
$primary-hover: #ff715b;
$gray: #e8e8e8;
$blue: #4a90e2;
$color-primary: #c10000;
$color-gray: #999;
$color-rakuten: #c10000;
$color-yshop:#ff9000;
$color-pink: #cb09d8;
$color-green: #36a200;
$color-blue: #038fde;

body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto-Regular';
    background-color: #fafbfd;
    color: #393c45 !important;
}
.mr-l-10 {
    margin-left: 10px;
}
.navbar-toggle {
    border: none;
}

select, input, textarea {
    outline: none;
    border-radius: 2px;
}
.ant-input, .ant-btn {
    border-radius: 2px;
}

.btn-facebook {
    outline: none;
    background-color: #4d75b9;
    margin-bottom: 5px;
    &:hover, &:focus, &:active {
        outline: none !important;
        background-color: #3c5e97;
        color: #fff;
    }
}

.btn-primary {
    color: #fff;
    background-color: $primary;
    border-color: $primary;
}

.btn-google {
    outline: none !important;
    color: #fff !important;
    background-color: #e5101d !important;
    &:hover, &:focus, &:active {
        outline: none !important;
        background-color: #bc000b !important;
    }
}

.item-profile {
    .ant-dropdown-menu {
        border-radius: 2px;
    }
    .ant-dropdown-menu-item {
        height: 35px;
        i {
            color: $color-gray;
            margin-right: 10px;
        }
        &:hover {
            span {
                color: $primary;
            }
        }
    }
    hr {
        margin: 0px;
    }
}
//common
.w-100 {
    width: 100%;
}

.panel-header {
    margin-bottom: 8px;
    height: 42px;
    line-height: 40px;
    display: inline-block;
    width: 100%;
    padding: 0px 15px;
    margin: 0px;
    // background-image: linear-gradient(to top, rgba(214, 218, 228, 0), rgba(214, 218, 228, 0.5));
    background: #fff;
    border-bottom: 1px solid #f0f0f0;
    box-shadow: 0 0 5px 0px rgba(0,0,0,.1);
    .header-title {
        font-size: 16px;
        margin: 0px;
        line-height: 40px;
        color: #444;
        position: relative;
        padding-top: 0px;
        a {
            color: #444;
            &:hover {
                color: #0d7ecc;
            }
        }
        .icon {
            position: absolute;
            left: 0px;
            top: 6px;
            font-size: 25px;
        }
        .icon-next {
            margin: 0px 10px;
        }

        .active {
            color: #0d7ecc;
            font-family: "Roboto-Medium";
        }
        button.pull-right {
            margin-top: 5px;
        }
        .ant-btn-icon-only.ant-btn-sm {
            width: 26px;
            height: 26px;
            padding: 0px 0;
            font-size: 14px;
            border-radius: 2px;
            line-height: 26px;
        }
        .group-action {
            float: right;
            padding-left: 15px;
            border-left: 1px solid #f0f0f0;
            .ant-input-group-wrapper {
                vertical-align: middle;
            }
            .title-price {
                font-size: 14px;
                padding-left: 15px;
                border-radius: 2px;
                background: #f0f2f5;
                padding: 0px 10px;
                height: 25px;
                line-height: 26px;
                margin-left: 15px;
            }
        }
    }
}

.panel-body {
    background: #fff;
    border-radius: 0px;
    margin-bottom: 15px;
    padding: 15px;
    min-height: calc(100vh - 150px);
    .panel-header {
        .title {
            font-size: 16px;
            margin-right: 15px;
        }
        .search {
            position: relative;
            padding-left: 110px;
            .title {
                position: absolute;
                left: 15px;
                top: 5px;
            }
        }
        .btn {
            border-radius: 2px;
        }
    }
    .total-filter {
        margin: 0px;
        font-size: 16px;
        font-weight: 400;
        img {
            height: 20px;
            margin-right: 10px;
        }
        span {
            color: #065db1;
            font-family: 'Roboto-Medium';
        }
    }
}
.table-main {
    .ant-table-thead {
        th {
            text-transform: uppercase;
            font-family: 'Roboto-Medium';
        }
    }
    &.small {
        .ant-table-thead {
            th {
                font-size: 13px;
            }
        }
    }
    .ant-table-tbody {
        td {
            font-size: 13px;
            &.ant-table-row-expand-icon-cell {
                span {
                    display: inline-block;
                    text-align: center;
                }
            }
            label {
                font-weight: 400;
                margin: 0px;
            }
        }
    }
    .btn-print {
        padding: 2px 5px;
        height: 22px;
        line-height: 13px;
        font-size: 13px;
    }
    // .ant-table-expanded-row > .ant-table-cell  {
    //     border: 1px solid $primary;
    //     border-top: unset;
    // }
    .table-view-children {
        max-width: calc(100vw - 95px);
        overflow: hidden;
        .table-chidren {
            .ant-table-small {
                margin: 0px !important;
                .ant-table-thead {
                    font-size: 12px;
                }
                .ant-table-cell {
                    font-size: 12px;
                }
            }
        }
    }
}

.ant-drawer-close {
    background: #ececec;
    border: 1px solid #ececec;
    padding: 6px;
    top: 12px;
    right: 20px;
    border-radius: 3px;
    &:hover {
        background: red;
        border: 1px solid red;
        color: #fff;
    }
}
.ant-form-item.required{
  .ant-form-item-label {
      label::after {
          display: inline-block;
          margin: 0 8px 10px 2px;
          color: #f5222d;
          font-size: 14px;
          font-family: SimSun,sans-serif;
          line-height: 1;
          content: '*';
      }
  }
}
label.required::after {
  display: inline-block;
  margin: 0 8px 10px 2px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun,sans-serif;
  line-height: 1;
  content: '*';
}

.note-red {
  &::before {
      display: inline-block;
      margin: 0 8px 10px 2px;
      color: #f5222d;
      font-size: 16px;
      font-family: SimSun,sans-serif;
      line-height: 1;
      content: '*';
  }
}
